import './App.css';
import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import GFApplicationForm, {SENDING_NONE, SENDING_IN_PROGRESS, SENDING_FAILED, SENDING_SUCCEEDED} from './GFApplicationForm';
import axios from "axios";

import useStyles from './styles';

// Duplicates are okay and will be filtered out
import tbFormConfig from './tbFormConfig';

function App() {

  // Possible values: "none", "sending", "success", "fail"
  const [sendingStatus, setSendingStatus] = useState( SENDING_NONE );
  const [errorMessage, setErrorMessage] = useState( "" );

  /**
   * Returns a copy of obj where all the fields values are replaced with JSON.strigify version
   * It is helpful for e.g. passing array as a single string
   *
   * @param exceptionFieldList array of field names not to stringify. For example, you wouldn't
   *        want to stringify the File fielss
   */
  function stringifyObjectFields(obj, exceptionFieldList = []) {
    let copy = {...obj};
    for( const field in copy) {
      if(exceptionFieldList.indexOf(field) !== -1 ) {
        continue;
      }
      copy[field] = JSON.stringify(copy[field]);
    }
    return copy;
  }

  function handleSubmitWish(formValuesFromForm) {
    console.log("handleSubmitWish:", formValuesFromForm);
    if(formValuesFromForm.category !== "Art & Animation") {
      // styles are sent only for Art & Animation category
      formValuesFromForm.artAndAnimationStyles = [];
    }
    formValuesFromForm.seniority_old = formValuesFromForm.seniorityLevel;
    formValuesFromForm.specialities_old = formValuesFromForm.specialities;
    let readyForPosting = stringifyObjectFields(formValuesFromForm, "cvFile");
    if( readyForPosting.cvFile ) {
      const cvFileSize = readyForPosting.cvFile.size;
      const cvFileSizeMB = cvFileSize / 1024 / 1024;
      if( cvFileSizeMB > tbFormConfig.maxCVFileSizeMB ) {
        setErrorMessage(`CV file size has to be ${tbFormConfig.maxCVFileSizeMB}MB max`);
        return;
      }
    }

    postForm(readyForPosting);
  }


  /**
   * @param formValuesReadyForPosting means that no resolving is needed, everything's ready for posting
   */
  function postForm(formValuesReadyForPosting) {
    console.log("postForm", formValuesReadyForPosting)
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };

    let formData = new FormData();
    formData.append("form-name", "personregistration");

    for(const fieldName in formValuesReadyForPosting) {
      console.log('fieldName: ', fieldName);
      formData.append(fieldName, formValuesReadyForPosting[fieldName]);
    }

    setSendingStatus(SENDING_IN_PROGRESS);
    setErrorMessage("");
    console.log('formData: ', formData);
    axios.post("/", formData, config)
    .then(res => {
      console.log(res);
      console.log(res.data);
      setSendingStatus(SENDING_SUCCEEDED);
    })
    .catch(err => {
      console.log("error posting: ", err)
      if(err.response && err.response.data) {
          console.log("Error that has some data: ", err.response.data);
          setErrorMessage(err.response.data);
      }
      setSendingStatus(SENDING_FAILED);
    });
  }

  const classes = useStyles();

  return (
    <div className="App">
      <Box className={classes.titleBar}>
        <Container className={classes.titleCaptionsContainer}>
          <Grid className={classes.titleCaptionsInnerContainer} container direction="row" alignItems="center" justify="center">
            {/* <Grid item>
              <img className={classes.titleLogo} src="logo.png" alt="Game factory talents logo"/>
            </Grid> */}
            <Grid item className={classes.titleLogoContainer}>
              <img className={classes.titleLogo} src="board-name-logo.jpg" alt="Game factory talents title logo"/>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container className={classes.formWrapper}>
        <GFApplicationForm
          skillTree = {tbFormConfig.skillTree}
          countries = {tbFormConfig.countries}
          employmentOptions = {tbFormConfig.employmentOptions}
          sendingStatus = {sendingStatus}
          errorMessage = {errorMessage}
          onSubmitWish = {handleSubmitWish}
          maxCVFileSizeMB = {tbFormConfig.maxCVFileSizeMB}
        />
      </Container>
    </div>
  );
}
                    // onClick={handleFormSubmitBtnClick}

export default App;
